<template>
  <div v-if="isPermission">
    <select-shop :loadingButton="loadingButton" v-on:getData="loadData"></select-shop>
    <CCard class="shadow-sm">
      <CCardBody>
        <div class="row">
          <h2 id="phoneExportButton" class="col-md-10 col-sm-9 col-8 font-weight-normal">
            {{ $t("phoneReportTXT") }}
          </h2>
          <div class="col-md-2 col-sm-3 col-4 text-right text-success">
            <CButton id="phoneExportButton" v-on:click="exportReport()" v-if="isExport" block color="info">
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
          </div>
        </div>
        <div style="margin-top: 5px;">
          <show-date></show-date>
        </div>
        <CRow style="margin-bottom: -20px;">
          <CCol lg="1" md="1" sm="1" col="1" class="mb-3  text-center" >
            <div class="d-flex justify-content-center align-items-center h-100">{{ $t('search') }}</div>
          </CCol>
          <CCol lg="3" md="3" sm="3" col="3" class="mb-3">
            <select id="phoneSelectSearchType" class="custom-select" v-model="searchType">
              <option :id="'phoneOptionsearch'+ index" v-for="(option, index) in searchOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </CCol>
          <CCol col="6">
                  <div style="margin-left: 2px">
                    <CInput id="phoneKeywordInput" v-model.trim="keyword" 
                    :placeholder="getPlaceholder"
                    :disabled="isDisabled"/>
                  </div>
                </CCol>
                <CCol lg="2" md="2" sm="2" col="2">
                  <CButton id="phoneSearchButton" v-on:click="loadData()" block color="success" >
                    {{ $t("search") }}
                  </CButton>
                </CCol>
        </CRow>
        <hr />
        <CRow>
          <CCol sm="12" lg="12">
            <div style="overflow-x: auto">
              <DataTable
                id="phoneDatatable"
                style="white-space: nowrap"
                :items="items()"
                :fields="fields"
                hover
                border
              >
              </DataTable>
            </div>
          </CCol>
        </CRow>
      <pagination
            :meta_data="meta_data"
            v-on:next="loadData"
          >
          </pagination>  
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import util from "@/util/util";
import DataTable from "@/containers/DataTable";
import permis from "@/util/permission";
import { fetchPhoneData, exportPhoneReport } from "@/controller/report/memberController";
import Pagination from '@/containers/Pagination'

export default {
  components: {
    DataTable,
    Pagination
  },
  data() {
    return {
      isLoading: false,
      loadingButton: false,
      tableData: [],
      keyword: '',
      searchType: "",
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users", "permissions"]),
    isPermission() {
      return permis.findPermissionRead("report", this.$route.path);
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    startDate() {
      return moment(String(this.date.start)).format("YYYY-MM-DD");
    },
    endDate() {
      return moment(String(this.date.end)).format("YYYY-MM-DD");
    },
    fields() {
      return [
        {
          key: "phone",
          label: this.$i18n.t("tel"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "name",
          label: this.$i18n.t("customerName"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "address",
          label: this.$i18n.t("address"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "grandTotal",
          label: this.$i18n.t("grandTotal"),
          _classes: "text-right text-dark font-weight-normal",
        },
        {
          key: "typeOfOrder",
          label: this.$i18n.t("orderType"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "productPRUTypeName",
          label: this.$i18n.t("txtSalesChannels"),
          _classes: "text-left text-dark font-weight-normal",
        }
      ];
    },
    searchOptions() {
      return [
        { value: "", label: this.$t("allItems") },
        { value: "Member.phone", label: this.$t("tel") },
        { value: "Member.name", label: this.$t("customerName") }
      ];
    },
    isDisabled() {
      return this.searchType === '';
    },
    getPlaceholder() {
      if (this.searchType === 'Member.name') {
        return this.$t('searchMembersale');
      } else if (this.searchType === 'Member.phone') {
        return this.$t('searchtelsale');
      } else {
        return this.$t('dialogSelectCategory');
      }
    },
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      this.searchOptions = this.searchOptions.map(option => ({
        value: option.value,
        label: this.$t(option.label)
      }));
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...util,
    async loadData(page = 1) {
      this.isLoading = true;
      try {
        const response = await fetchPhoneData(this.startDate, this.endDate, page = 1, this.searchType, this.keyword);
        this.tableData = response.processedData
        const clonedMetaData = JSON.parse(JSON.stringify(response.metaData));
        this.meta_data.last_page = clonedMetaData.pageCount
        this.meta_data.current_page = clonedMetaData.currentPage
        this.meta_data.itemCount = clonedMetaData.itemCount
        this.meta_data.limit = clonedMetaData.limit
        this.meta_data.items = clonedMetaData.items
        this.isLoading = false;
        this.loadingButton = true;
      } catch (error) {
        console.error("Error loading data:", error);
        this.isLoading = false;
        this.loadingButton = false;
      }
    },
    items() {
      if (this.tableData.length > 0) {
        return this.tableData.map((data) => ({
          phone: data.phone || '-',
          name: data.name || '-',
          address: data.address || '-',
          grandTotal: util.convertCurrency(data.grandTotal),
          typeOfOrder: this.translateTypeOfOrder(data.typeOfOrder),
          productPRUTypeName: this.translateProductPRUType(data.productPRUTypeName),
        }));
      } else {
        return [];
      }
    },
    translateProductPRUType(name) {
      switch (name) {
        case 'ราคาขายปกติหน้าร้าน':
          return this.$t('txtStore');
        case 'ราคาขายโปรโมชั่น':
          return this.$t('promotionPrice');
        case 'ราคาขายส่งสมาชิก':
          return this.$t('memberPrice');
        case 'Kiosk':
          return 'Kiosk';
        case 'QRorder':
          return 'QR-Order';
        case 'Grab':
          return 'Grab';
        case 'Lineman':
          return 'Line Man';
        case 'Foodpanda':
          return 'Food Panda';
        case 'Shopee food':
          return 'Shopee Food'
        case 'Robinhood':
          return 'Robinhood'
        case 'True Food':
          return 'True Food'
        default:
          return '-';
      }
    },
    translateTypeOfOrder(order) {
      switch (order) {
        case 1:
          return this.$t('dineIn');
        case 2:
          return this.$t('takeAway');
        default:
          return '-';
      }
    },
    async exportReport() {
      try {
        let response = await exportPhoneReport(this.startDate, this.endDate, this.$i18n.locale, this.searchType, this.keyword);

        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const filename = this.$t('phoneReportTXT') + '.xlsx';
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.error('Error exporting report:', error);
      }
    },
  },
};
</script>
